define(['lodash', 'warmupUtils', 'experiment'], function (_, warmupUtils, experiment) {
    'use strict';

    let filesData = []; // eslint-disable-line santa/no-module-state
    let uploading = false; //eslint-disable-line santa/no-module-state
    const uploadedFilesNamespace = '_WixCodeAnonymUpload';
    const mediaTypesTranslation = {
        Image: 'picture',
        Document: 'document',
        Video: 'video'
    };

    function uploadFileToMediaPlatform(fileUploadData, upload_token, upload_url) {
        const mediaType = mediaTypesTranslation[fileUploadData.fileType];
        const formData = new FormData();

        formData.append('upload_token', upload_token);
        formData.append('media_type', mediaType);
        formData.append('file', fileUploadData.file);

        if (!fileUploadData.isMediaManagerExpOpen) {
            formData.append('ns', uploadedFilesNamespace);
        }

        if (fileUploadData.isMediaManagerExpOpen) {
            formData.append('parent_folder_id', 'visitor-uploads');
        }


        const ajaxObj = {
            type: 'POST',
            url: upload_url,
            success: response => {
                uploadNextFileOrFinish(fileUploadData, response);
            },
            error: err => {
                failUpload(fileUploadData, err);
            },
            data: formData,
            processData: false,
            contentType: false
        };

        warmupUtils.ajaxLibrary.ajax(ajaxObj);
    }


    function getUploadUrlAndSendFile(fileUploadData) {
        const mediaType = mediaTypesTranslation[fileUploadData.fileType];
        const isMediaManagerExpOpen = experiment.isOpen('AlwaysUploadToSiteMedia') || fileUploadData.isMediaManagerExpOpen; //eslint-disable-line no-restricted-syntax
        const url = `${isMediaManagerExpOpen ? 'https://files.wix.com/site/media/files/upload/url?media_type=' : 'https://files.wix.com/files/upload/url?media_type='}${mediaType}`;

        const ajaxObj = {
            type: 'GET',
            url,
            success: response => {
                uploadFileToMediaPlatform(fileUploadData, response.upload_token, response.upload_url);
            },
            error: err => {
                failUpload(fileUploadData, err);
            },
            headers: {
                Authorization: fileUploadData.authorizationToken,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };

        warmupUtils.ajaxLibrary.ajax(ajaxObj);
    }

    function uploadNextFileOrFinish(fileUploadData, response) {
        if (filesData.length === 0) {
            uploading = false;
            warmupUtils.loggingUtils.fedopsLogger.interactionEnded(warmupUtils.loggingUtils.fedopsLogger.INTERACTIONS.USER_FILE_UPLOAD.END);
            fileUploadData.onSuccess(response);
            return;
        }

        fileUploadData.onSuccess(response);
        startUploadFile();
    }

    function startUploadFile() {
        const fileUploadData = _.pullAt(filesData, 0)[0];

        uploading = true;
        getUploadUrlAndSendFile(fileUploadData);
    }

    function failUpload(fileUploadData, err) {
        uploading = false;
        fileUploadData.onFail(JSON.parse(err.response));
    }

    function getMediaManagerExperiment(isPreviewMode, userId, isExperimentOpen) {
        if (experiment.isOpen('AlwaysUploadToSiteMedia')) { //eslint-disable-line no-restricted-syntax
            return Promise.resolve(true);
        }
        if (isPreviewMode) {
            return Promise.resolve(isExperimentOpen('specs.media.MediaManager3'));
        }

        return new Promise((resolve, reject) => {
            const url = `/_api/wix-laboratory-server/laboratory/conductAllInScopeForRegisteredUser?scope=santa-viewer&userId=${userId}`;
            const ajaxObj = {
                type: 'GET',
                url,
                success: data => {
                    const isMediaManagerExpOpen = _.get(data, 'specs.media.MediaManager3') === 'true';
                    resolve(isMediaManagerExpOpen);
                },
                error: err => reject(err)
            };
            warmupUtils.ajaxLibrary.ajax(ajaxObj);
        });
    }

    // remove isMediaManagerExpOpen when merging specs.media.MediaManager3
    function uploadFile({file, siteMediaToken, fileType, uploadCompleteCB, uploadFailedCB, authToken, isPreviewMode, userId, isExperimentOpen}) {
        warmupUtils.loggingUtils.fedopsLogger.interactionStarted(warmupUtils.loggingUtils.fedopsLogger.INTERACTIONS.USER_FILE_UPLOAD.START);
        if (!siteMediaToken || !authToken) {
            throw new Error('No upload authorization token given!');
        }
        getMediaManagerExperiment(isPreviewMode, userId, isExperimentOpen)
            .then(isMediaManagerExpOpen => {
                const token = isMediaManagerExpOpen ? authToken : siteMediaToken;

                filesData = filesData.concat({
                    isMediaManagerExpOpen,
                    file,
                    authorizationToken: token,
                    fileType,
                    onSuccess: uploadCompleteCB,
                    onFail: uploadFailedCB
                });

                if (!uploading) {
                    startUploadFile();
                }
            })
            .catch(() => { throw new Error('error while trying to get media manager experiment'); });
    }

    return {
        uploadFile
    };
});
